import { gql, useQuery } from '@apollo/client';
import { AlertBanner, Link } from '@apollo/orbit';
import React from 'react';

import { settings } from 'src/app/account/routes';
import { PermissionWarning } from 'src/components/permissionGuards/permissionWarning/PermissionWarning';
import { useIdentity } from 'src/hooks/useIdentity';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { ignorePermissionsErrors } from 'src/lib/apollo/catchErrors';
import { appLinkContext } from 'src/lib/apollo/link';
import { GraphQLTypes } from 'src/lib/graphqlTypes';
import { useLDFlag } from 'src/lib/launchDarkly';

const ssoProviderQuery = gql`
  query UI__accountSsoProviderQuery($accountId: ID!) {
    account(id: $accountId) {
      id
      sso {
        provider
      }
    }
  }
`;

interface Props {
  accountId: string;
}

export const SsoMigrationBanner = ({ accountId }: Props) => {
  const { me, meLoading, isUser } = useIdentity();
  const track = useTrackCustomEvent();
  const bannerEnabled = useLDFlag('astro-self-serve-sso-migration-banner');
  const settingsPage = settings.path({ orgId: accountId, tab: 'security' });
  const { data, loading, error } = useQuery<
    GraphQLTypes.UI__accountSsoProviderQuery,
    GraphQLTypes.UI__accountSsoProviderQueryVariables
  >(ssoProviderQuery, {
    variables: { accountId },
    errorPolicy: 'all',
    context: appLinkContext({ catchErrors: [ignorePermissionsErrors] }),
  });

  const [isOpen, setIsOpen] = React.useState(true);
  const handleClose = () => setIsOpen(false);

  // Disable the banner if any of the following are true:
  //  - feature flag is disabled
  //  - queries are loading
  //  - we are not identified as a user
  //  - we are not an SSO user
  //  - there was an error checking SSO status
  //  - the current org has already migrated
  if (
    !bannerEnabled ||
    !me ||
    meLoading ||
    !isUser ||
    me.type !== GraphQLTypes.UserType.SSO ||
    loading ||
    error ||
    !data?.account?.sso ||
    data?.account?.sso?.provider === GraphQLTypes.OrganizationSSOProvider.APOLLO
  ) {
    return null;
  }

  const canProvisionSsoBanner = (
    <AlertBanner status="warning">
      Your current SSO configuration will stop working on November 15,
      2024.&nbsp;
      <Link
        to="https://www.apollographql.com/docs/graphos/org/sso/self-service/migration"
        isExternal
        onClick={() =>
          track({
            action: 'open_migration_guide',
            category: 'SSO Wizard',
          })
        }
      >
        See our guide on how to migrate
      </Link>
      &nbsp;before this date so your team can continue accessing GraphOS
      Studio.&nbsp;
      <Link
        to={settingsPage}
        onClick={() =>
          track({
            action: 'click_migration_banner_cta',
            category: 'SSO Wizard',
          })
        }
      >
        Migrate to new SSO
      </Link>
    </AlertBanner>
  );

  const cannotProvisionSsoBanner = (
    <AlertBanner
      status="warning"
      isDismissible={true}
      isOpen={isOpen}
      onDismiss={handleClose}
    >
      Your current SSO configuration will stop working on November 15, 2024.
      Please contact your Org Admin to migrate before this date so your team can
      continue accessing GraphOS Studio.
    </AlertBanner>
  );

  return (
    <PermissionWarning
      accountId={accountId}
      accountPermissions="canProvisionSSO"
      childRenderer={(hasPermission) => {
        if (hasPermission) {
          return canProvisionSsoBanner;
        } else {
          return cannotProvisionSsoBanner;
        }
      }}
    />
  );
};
