import React from 'react';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from 'src/app/embeddableSandbox/isEmbeddableSandboxRoute';
import { IntrospectionFailure } from 'src/app/graph/explorerPage/hooks/useExplorerState/useSchema/useSchemaFromEndpointIntrospection/useSchemaFromEndpointIntrospection';
import { GraphRef, graphRefToString } from 'src/app/graph/hooks/useGraphRef';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import Config from 'src/lib/config';
import { LaunchDarklyIdentityTypes } from 'src/lib/launchDarkly/types';

const { studioUrl } = Config.settings;

export const getAmplitudeDeviceId = async () => {
  const result = await awaitDeviceId();
  return result;
};

function awaitDeviceId() {
  return new Promise((resolve, _) => {
    setTimeout(() => {
      const deviceId = ampli.client.getDeviceId();
      resolve(deviceId);
    }, 1000);
  });
}

export type AmplitudeHelpersShouldTrackUserParams = {
  ldIdentityType: LaunchDarklyIdentityTypes;
  excludeRouteFromTracking: boolean;
  amplitudeConfiguration: {
    trackingEnabled: boolean;
    trackAnonymousUsers: boolean;
    trackEmbeddedUsers: boolean;
  };
};

export const shouldOptOutOfAmplitude = (
  args: AmplitudeHelpersShouldTrackUserParams,
) => {
  return !shouldAmplitudeTrackUser(args);
};

export const shouldAmplitudeTrackUser = ({
  ldIdentityType,
  excludeRouteFromTracking,
  amplitudeConfiguration,
}: AmplitudeHelpersShouldTrackUserParams) => {
  if (
    ldIdentityType === LaunchDarklyIdentityTypes.unknown ||
    excludeRouteFromTracking ||
    !amplitudeConfiguration.trackingEnabled
  ) {
    return false;
  }

  if (isEmbeddableSandboxRoute()) {
    return amplitudeConfiguration.trackEmbeddedUsers;
  }

  if (ldIdentityType === LaunchDarklyIdentityTypes.anonymous) {
    return amplitudeConfiguration.trackAnonymousUsers;
  } else {
    return true;
  }
};

export const AmplitudeHelpers = {
  params: {
    applicationMode: () => ({
      'Application Mode': AmplitudeHelpers.applicationMode(),
    }),
    graphId: (graphId: string) => ({
      'Graph Id': graphId,
    }),
  },
  applicationMode: () => {
    if (isEmbeddableExplorerRoute()) {
      return 'embedded-explorer';
    }

    if (isEmbeddableSandboxRoute()) {
      return 'embedded-sandbox';
    }

    return 'studio';
  },
  maybePhoneHome: () => {
    if (isEmbeddableSandboxRoute()) {
      getAmplitudeDeviceId().then((deviceId) => {
        const url = `${studioUrl}/iframeIdentitySync?did=${deviceId}`;
        const iframe = document.createElement('iframe');
        iframe.src = url;
        setTimeout(() => {
          // Be nice and avoid hogging a socket for the first 10 seconds.
          document.body.appendChild(iframe);
        }, 10 /* seconds */ * 1000 /* milliseconds */);
      });
    }
  },
  useTrackL1PageView: (
    pageName:
      | 'changeLogL1'
      | 'checksL1'
      | 'clientsL1'
      | 'cloudRouterL1'
      | 'explorer'
      | 'graphSettingsL1'
      | 'launchesL1'
      | 'operationsL1'
      | 'schemaL1'
      | 'settingsL1'
      | 'subgraphsL1'
      | 'variantsHomeL1',
    { graphRef }: { graphRef: GraphRef | null },
  ) => {
    React.useEffect(() => {
      ampli[`${pageName}Viewed`]({
        'Application Mode': AmplitudeHelpers.applicationMode(),
        graphRef: graphRefToString(graphRef) ?? 'unknown',
      });
    }, [pageName, graphRef]);
  },
};

type IntrospectionRequestStatus = string | IntrospectionFailure | undefined;

let previousIntrospectionRequestStatus: IntrospectionRequestStatus;

export const trackIntrospectionRequestStatus = (
  latestInstrospectionRequestStatus: IntrospectionRequestStatus,
) => {
  if (
    latestInstrospectionRequestStatus === previousIntrospectionRequestStatus
  ) {
    return;
  }

  if (latestInstrospectionRequestStatus === 'success') {
    ampli.endpointIntrospectionSuccess({
      'Application Mode': AmplitudeHelpers.applicationMode(),
    });
  } else {
    ampli.endpointIntrospectionError({
      'Application Mode': AmplitudeHelpers.applicationMode(),
      'Error Details': latestInstrospectionRequestStatus,
      'Error Message': `Failed to introspect user's API endpoint`,
      'Error Type': latestInstrospectionRequestStatus,
    });
  }

  previousIntrospectionRequestStatus = latestInstrospectionRequestStatus;
};
