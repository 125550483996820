import { useApolloClient } from '@apollo/client';
import IconEmail from '@apollo/icons/default/IconEmail.svg';
import IconGitHubSolid from '@apollo/icons/default/IconGitHubSolid.svg';
import IconLock from '@apollo/icons/default/IconLock.svg';
import {
  Alert,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
} from '@apollo/orbit';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ClickableText } from 'src/components/common/clickableText/ClickableText';
import { Form } from 'src/components/common/form/Form';
import { PrivacyPolicyLink } from 'src/components/privacyPolicyLink/PrivacyPolicyLink';
import { TermsOfServiceLink } from 'src/components/termsOfServiceLink/TermsOfServiceLink';
import { useHandleProviderSubmit } from 'src/hooks/useHandleProviderSubmit';
import { writeToLocalStorage } from 'src/hooks/useLocalStorage';
import { useLogin } from 'src/hooks/useLogin';
import { useQueryParams } from 'src/hooks/useQueryParams';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { useTrackCustomEvent } from 'src/hooks/useTrackCustomEvent';
import { EventCategory } from 'src/lib/analytics';
import { ampli } from 'src/lib/analytics/amplitude/vendor';
import { VALID_EMAIL } from 'src/lib/email';
import {
  IncorrectEmailOrPasswordRegex,
  IncorrectPasswordRegex,
} from 'src/lib/graphqlTypes/serverErrors';
import { useLDFlag } from 'src/lib/launchDarkly';
import { getInvisibleRecaptchaToken } from 'src/lib/recaptcha';
import { relativeURL } from 'src/lib/relativeURL';

import * as routes from '../../routes/routes';
import { SSOPage } from '../ssoPage/SSOPage';

let cookiesEnabled = true;
try {
  // Create cookie
  document.cookie = 'cookietest=1';
  const cookieExists = document.cookie.indexOf('cookietest=') !== -1;
  // Delete cookie
  document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
  if (!cookieExists) throw new Error('cookie not written');
} catch (e) {
  cookiesEnabled = false;
}

interface Props {
  initialState?: Partial<State>;
}

interface ValidationErrors {
  email?: string;
  password?: string;
  global?: string;
}

interface State {
  email: string;
  password: string;
  errors: ValidationErrors;
}

function useClearMeAndVariantPermissionsFromCache() {
  const client = useApolloClient();

  return () => {
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'me',
    });

    // Clear variant from cache for embedded pages authentication.
    // In the embed log in flow, we might auto invite folks to a graph in the embed,
    // so they will have updated permissions that need to be fetched.
    client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'variant',
    });

    // Remove all dangling references to force refetches of all this data.
    client.cache.gc();
  };
}

export const LoginPage = ({ initialState }: Props) => {
  const { type } = useRouteParams(routes.login);

  const state = {
    email: '',
    password: '',
    errors: {},
    // This is so we can test state in storybook
    ...(initialState || {}),
  };
  const clearMeAndVariantPermissionsFromCache =
    useClearMeAndVariantPermissionsFromCache();
  const [email, setEmail] = useState<string>(state.email);
  const [password, setPassword] = useState<string>(state.password);
  const [errors, setErrors] = useState<ValidationErrors>(state.errors);
  const [loggingInGithub, setLoggingInGithub] = useState<boolean>(false);
  const [loggingInSSO] = useState<boolean>(false);
  const [loggingInSSOV2, setLoggingInSSOV2] = useState<boolean>(type === 'sso');
  const { from } = useRouteParams(routes.login);
  const trackCustomEvent = useTrackCustomEvent();

  const history = useHistory();
  const location = useLocation();
  const { loginError, loginErrorMessage } = useQueryParams();
  const [{ loggingIn }, login] = useLogin();

  const handleSignInSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();

    let hadErrors = false;
    if (!password) {
      hadErrors = true;
      setErrors({ password: 'Please provide a password.' });
    }
    if (!email) {
      hadErrors = true;
      setErrors((oldErrors) => ({
        ...oldErrors,
        email: 'Please provide an email address.',
      }));
    } else if (!VALID_EMAIL.test(email)) {
      hadErrors = true;
      setErrors((oldErrors) => ({
        ...oldErrors,
        email: 'Please provide an email address.',
      }));
    }

    if (!hadErrors) {
      const loginResult = await login(
        email,
        password,
        await getInvisibleRecaptchaToken({ action: 'login' }),
      );

      setErrors((oldErrors) => ({
        ...oldErrors,
        global: loginResult.error
          ? IncorrectPasswordRegex.test(loginResult.error.message)
            ? 'Incorrect password, please try another.'
            : IncorrectEmailOrPasswordRegex.test(loginResult.error.message)
            ? 'Incorrect email or password, please try again'
            : 'Error logging in.'
          : undefined,
      }));

      if (loginResult.logInSuccessful) {
        trackCustomEvent({
          action: 'sign_in_flow',
          // Old event, this style of category is deprecated
          category: 'Sign in with email button' as EventCategory,
          label: 'Studio sign in flow',
        });
        clearMeAndVariantPermissionsFromCache();

        // if they came from the welcome route, just push them into the app after logging in vs sending back to welcome
        const destination =
          !from || from.includes(routes.welcome.definition) ? '/' : from;

        history.push(relativeURL(destination));
      }
    }
  };

  React.useEffect(() => {
    if (!type) return;

    // Set the default `referrer`
    writeToLocalStorage('utm', (previousValue) => ({
      ...previousValue,
      referrer: previousValue?.referrer ?? type,
    }));
  }, [type]);

  const returnToUrl = from || window.location.origin;

  const handleProviderSubmitGithub = useHandleProviderSubmit({
    provider: 'github',
    // GitHub signins should always redirect to the welcome page, so we don't
    // lose the referrer `type` in the query param. Passing `from` to the welcome
    // route will redirect to `from` when / if the privacy policy is signed
    callbackUrl: routes.welcome.pathFrom({
      location,
      fromRouteConfig: routes.signup,
    }),
    setLoggingIn: setLoggingInGithub,
    type,
  });

  let error;
  if (loginError) {
    if (loginErrorMessage) {
      error = `There was a problem logging in through your provider. Details: ${loginErrorMessage}`;
    } else {
      error = 'There was a problem logging in through your provider.';
    }
  } else if (!cookiesEnabled) {
    error =
      'Studio requires cookies to log in. Please enable cookies in your browser before proceeding.';
  }

  // Any additional errors that could be returned from the login request
  if (errors.global) {
    error = errors.global;
  }

  const redirectAllSignupstoWWW = useLDFlag(
    'betelgeuse-redirect-studio-signups',
  );

  return (
    <>
      {error && (
        <Alert className="mb-10" status="error">
          {error}
        </Alert>
      )}
      <div className="relative mb-8">
        <div className="text-3xl font-semibold">Sign in</div>

        <div className="absolute right-0 top-0 mt-4 text-sm">
          New to Apollo?{' '}
          {redirectAllSignupstoWWW ? (
            <ClickableText
              className="text-link underline"
              as="a"
              rel="noopener noreferrer"
              href="https://www.apollographql.com/signup"
              onClick={() => {
                ampli.studioDirectSignupClicked();
                ampli.studioDirectSignupRedirected();
              }}
            >
              Let's get started.
            </ClickableText>
          ) : (
            <ClickableText
              className="text-link underline"
              onClick={() => {
                ampli.studioDirectSignupClicked();
              }}
              as={
                <Link
                  // https://www.apollographql.com/signup
                  to={routes.signup.locationFrom({
                    location,
                    fromRouteConfig: routes.login,
                  })}
                />
              }
            >
              Let's get started.
            </ClickableText>
          )}
        </div>
      </div>

      <div className="my-12">
        <div className="flex flex-col gap-4 pb-4">
          <Button
            type="button"
            size="lg"
            leftIcon={<IconGitHubSolid />}
            variant="primary"
            className="mr-4 w-full"
            onClick={handleProviderSubmitGithub}
            isDisabled={loggingIn || loggingInSSO || loggingInGithub}
            isLoading={loggingInGithub}
            hidden={loggingInSSOV2}
          >
            Sign in with GitHub
          </Button>
          <Button
            type="button"
            size="lg"
            leftIcon={<IconLock className="size-4" />}
            variant="secondary"
            className="w-full"
            isDisabled={loggingInSSOV2}
            isLoading={loggingInSSO}
            onClick={() => {
              setLoggingInSSOV2(true);
            }}
          >
            {loggingInSSOV2 ? 'SSO Enabled' : 'Sign in with SSO'}
          </Button>
          {loggingInSSOV2 ? (
            <SSOPage
              returnToUrl={returnToUrl}
              setLoggingInSSOV2={setLoggingInSSOV2}
            />
          ) : null}
        </div>

        <div className="flex items-center py-6" hidden={loggingInSSOV2}>
          <hr className="m-0 flex-1 border-primary" />
          <span className="px-2 text-xs text-secondary">OR</span>
          <hr className="m-0 flex-1 border-primary" />
        </div>

        <Form
          onSubmit={handleSignInSubmit}
          noValidate
          className="mt-4 flex flex-col gap-4"
          hidden={loggingInSSOV2}
        >
          <FormControl isInvalid={!!errors.email}>
            <FormLabel hidden>Email</FormLabel>
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              className="w-full"
              onChange={(event) => {
                setEmail(event.target.value);
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  email: undefined,
                }));
              }}
              leftElement={<IconEmail />}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl className="flex flex-col" isInvalid={!!errors.password}>
            <FormLabel hidden>Password</FormLabel>
            <Input
              className="w-full"
              placeholder="Password"
              type="password"
              name="password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
                setErrors((oldErrors) => ({
                  ...oldErrors,
                  password: undefined,
                }));
              }}
              leftElement={<IconLock />}
            />
            <Link to="/forgot-password" className="text-xs">
              Forgot password?
            </Link>
            <FormErrorMessage>{errors.password}</FormErrorMessage>
          </FormControl>
          <div>
            <Button
              type="submit"
              isDisabled={loggingInGithub || loggingInSSO}
              isLoading={loggingIn}
              size="lg"
              className="w-full"
              variant="secondary"
            >
              Sign in
            </Button>
          </div>
        </Form>
      </div>
      <RecaptchaDisclaimer />
    </>
  );
};

// this disclaimer is required in order to hide the recaptcha badge
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
const RecaptchaDisclaimer = () => {
  return (
    <>
      <div className="mt-6 px-14 text-center text-sm text-secondary">
        This site is protected by reCAPTCHA and the Google{' '}
        <PrivacyPolicyLink href="https://policies.google.com/privacy" /> and{' '}
        <TermsOfServiceLink href="https://policies.google.com/terms" /> apply.
      </div>
    </>
  );
};
