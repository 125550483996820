import React, { useEffect } from 'react';

import { useRouteParams } from 'src/hooks/useRouteParams';

import { safeParseHeadersState } from '../graph/explorerPage/hooks/useLoadExplorerStateFromURL';
import {
  useLocalStorageGraphIdentifier,
  usePerGraphIdentifierLocalStorage,
} from '../graph/explorerPage/hooks/usePerGraphIdentifierLocalStorage';
import { embeddableSandboxRouteConfig } from '../graph/routes';

import {
  EmbeddableSandboxConfig,
  STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG,
} from './initialConfigValues';

/**
 * @returns The locally stored config options from the query params passed to
 * studio-ui from the EmbeddedSandbox class
 *
 * Why can't we just use the query params directly? In case the url is modified
 * or cleared, by navigating pages, which users do all the time, we don't want the config
 * to be washed away. On the first render, we store the config from query params
 * in local storage, and then we use this in our logic from then on.
 */
export const useEmbeddableSandboxConfig = (): EmbeddableSandboxConfig => {
  // We only care about the query params on the first render,
  // after the first render they are unreliable. They could have been wiped,
  // a query param of the same name might have been modified by another page etc.
  // We only want to update the local storage config values on the first render
  const [hasCompletedFirstRender, setFirstRenderComplete] =
    React.useState(false);

  const graphIdentifier = useLocalStorageGraphIdentifier({
    shouldNamespaceSandboxByEndpoint: false,
    shouldOnlyStoreForRegisteredGraphs: false,
  });
  const [embeddableSandboxConfigOptions, setEmbeddableSandboxConfigOptions] =
    usePerGraphIdentifierLocalStorage({
      graphIdentifier,
      key: 'embeddableSandboxConfigOptions',
      stableInitialValue: STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG,
    });

  const {
    endpointIsEditable,
    hideCookieToggle,
    defaultIncludeCookies,
    initialRequestQueryPlan,
    shouldDefaultAutoupdateSchema,
    sharedHeaders,
    runTelemetry,
    version,
    subscriptionEndpoint,
    sendOperationHeadersInIntrospection,
    initialRequestConnectorsDebugging,
  } = useRouteParams(embeddableSandboxRouteConfig);

  useEffect(() => {
    if (!hasCompletedFirstRender) {
      const configWithDefaultsFromQueryParams = {
        endpointIsEditable:
          typeof endpointIsEditable === 'string'
            ? endpointIsEditable === 'true'
            : endpointIsEditable === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.endpointIsEditable,
        hideCookieToggle:
          typeof hideCookieToggle === 'string'
            ? hideCookieToggle === 'true'
            : hideCookieToggle === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.hideCookieToggle,
        defaultIncludeCookies:
          typeof defaultIncludeCookies === 'string'
            ? defaultIncludeCookies === 'true'
            : defaultIncludeCookies === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.defaultIncludeCookies,
        initialRequestQueryPlan:
          typeof initialRequestQueryPlan === 'string'
            ? initialRequestQueryPlan === 'true'
            : initialRequestQueryPlan === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.initialRequestQueryPlan,
        initialRequestConnectorsDebugging:
          typeof initialRequestConnectorsDebugging === 'string'
            ? initialRequestConnectorsDebugging === 'true'
            : initialRequestConnectorsDebugging === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.initialRequestConnectorsDebugging,
        shouldDefaultAutoupdateSchema:
          typeof shouldDefaultAutoupdateSchema === 'string'
            ? shouldDefaultAutoupdateSchema === 'true'
            : shouldDefaultAutoupdateSchema === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.shouldDefaultAutoupdateSchema,
        sharedHeaders: sharedHeaders
          ? safeParseHeadersState(sharedHeaders)
          : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.sharedHeaders,
        runTelemetry:
          typeof runTelemetry === 'string'
            ? runTelemetry === 'true'
            : runTelemetry === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.runTelemetry,
        version,
        subscriptionEndpoint,
        sendOperationHeadersInIntrospection:
          typeof sendOperationHeadersInIntrospection === 'string'
            ? sendOperationHeadersInIntrospection === 'true'
            : sendOperationHeadersInIntrospection === 'false'
            ? false
            : STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG.sendOperationHeadersInIntrospection,
      };

      setEmbeddableSandboxConfigOptions(configWithDefaultsFromQueryParams);
    }
    setFirstRenderComplete(true);
  }, [
    defaultIncludeCookies,
    endpointIsEditable,
    hasCompletedFirstRender,
    hideCookieToggle,
    initialRequestQueryPlan,
    setEmbeddableSandboxConfigOptions,
    sharedHeaders,
    shouldDefaultAutoupdateSchema,
    runTelemetry,
    version,
    subscriptionEndpoint,
    sendOperationHeadersInIntrospection,
    initialRequestConnectorsDebugging,
  ]);
  return embeddableSandboxConfigOptions;
};
