import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom-v5-compat';

import { LoggedInRoute } from 'src/components/common/authenticatedRoutes/AuthenticatedRoutes';
import { ErrorBoundary } from 'src/components/common/errorBoundary/ErrorBoundary';
import { Loading } from 'src/components/common/loading/Loading';
import { NotFound } from 'src/components/common/notFound/NotFound';
import { Toasts } from 'src/components/toast/Toast';
import { useInitializeClickAnalytics } from 'src/hooks/useInitializeClickAnalytics';
import { useSyncAmplitudeForEmbedded } from 'src/hooks/useSyncAmplitudeForEmbedded';
import { useTelemetryInitializer } from 'src/hooks/useTelemetryInitializer';
import Config from 'src/lib/config';
import { BackendRouter } from 'src/lib/routers';
import { listenForKonami } from 'src/templates/index/konami';

import { EmbedLogoutPage } from '../account/logoutPage/EmbedLogoutPage';
import { LogoutPage } from '../account/logoutPage/LogoutPage';
import { AppLayout } from '../appLayout/AppLayout';
import { BuildInfoPage } from '../buildInfo/buildInfoPage/BuildInfoPage';
import { EmailPreferencesPage } from '../emailPreferences/emailPreferencesPage/EmailPreferencesPage';
import { EmbedAuthenticator } from '../embeddableExplorer/EmbedAuthenticator';
import { EmbeddableExplorer } from '../embeddableExplorer/EmbeddableExplorer';
import { isEmbeddableExplorerRoute } from '../embeddableExplorer/isEmbeddableExplorerRoute';
import { isEmbeddableSandboxRoute } from '../embeddableSandbox/isEmbeddableSandboxRoute';
import { PreflightScriptOAuthTokenFetcher } from '../graph/explorerPage/explorerSettings/PreflightScriptOAuthConfirmationModal';
import {
  atlasExplorerRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
} from '../graph/routes';
import {
  Onboarding,
  ownedRoutes as onboardingOwnedRoutes,
} from '../onboarding/Onboarding';
import * as routes from '../onboarding/routes';
import * as sharedRoutes from '../shared/routes';

import { ServiceWorkerRefreshPrompts } from './ServiceWorkerRefreshPrompts';

const EmbeddableSandbox = React.lazy(async () => ({
  default: (
    await import(
      /* webpackChunkName: "EmbeddableSandbox" */ '../embeddableSandbox/EmbeddableSandbox'
    )
  ).EmbeddableSandbox,
}));

/*
 * Studio – the app!
 *
 * Authentication is enforced on LoggedInRoute's, Route's are public,
 * but will need to be placed above
 * <LoggedInRoute path="/" component={AppLayout} />
 * since it matches everything
 *
 * If you visit a protect route while logged out, you'll be forwarded to /login.
 * If you visited /login while logged in, you'll be sent to the root url /.
 */
export const RoutedApp = Sentry.withProfiler(() => {
  useEffect(() => {
    document.addEventListener('keydown', listenForKonami);
    return () => document.removeEventListener('keydown', listenForKonami);
  }, []);

  useInitializeClickAnalytics();

  const isEmbeddableExplorer = isEmbeddableExplorerRoute();
  const isEmbeddableSandbox = isEmbeddableSandboxRoute();

  useSyncAmplitudeForEmbedded();
  useTelemetryInitializer(isEmbeddableExplorer || isEmbeddableSandbox);

  const location = useLocation();

  return (
    <ErrorBoundary>
      <ServiceWorkerRefreshPrompts />
      <React.Suspense fallback={<Loading />}>
        {isEmbeddableExplorer ? (
          <Switch>
            <Route
              path={routes.embedLogoutRouteConfig.definition}
              component={EmbedLogoutPage}
            />
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.definition}
              component={PreflightScriptOAuthTokenFetcher}
            />
            <Route render={() => <EmbeddableExplorer />} />
          </Switch>
        ) : isEmbeddableSandbox ? (
          <Switch>
            <Route
              path={routes.embedLogoutRouteConfig.definition}
              component={EmbedLogoutPage}
            />
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.definition}
              component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path={sandboxGraphRouteConfig.definition}
              component={EmbeddableSandbox}
            />
          </Switch>
        ) : (
          <Switch>
            <Route
              path={sharedRoutes.preflightOAuth2RedirectRoute.definition}
              component={PreflightScriptOAuthTokenFetcher}
            />
            <Route
              path="/account/:orgId/invite/:joinToken"
              render={({ match }) => (
                <Redirect
                  to={routes.inviteLanding.location({
                    orgId: match?.params.orgId,
                    joinToken: match?.params.joinToken,
                  })}
                />
              )}
            />
            <Route path="/logout" component={LogoutPage} />
            <Route path="/404" component={NotFound} />
            <Route
              exact={true}
              path={routes.loginWithProvider.definition}
              render={({ match: { params } }) => {
                BackendRouter.go('LoginWithProviderAndCallback', {
                  ...params,
                  callbackUrl: Config.absoluteUrl,
                });
                return null;
              }}
            />
            <Route path={[...onboardingOwnedRoutes]} component={Onboarding} />
            <Route path="/build" component={BuildInfoPage} />
            <Route path="/email-preferences" component={EmailPreferencesPage} />
            <Route
              path={routes.embedAuthenticationRouteConfig.definition}
              render={() => (
                <>
                  <EmbedAuthenticator />
                  <Toasts />
                </>
              )}
            />
            <Route
              path={routes.sandboxLanding.definition}
              exact={true}
              render={() => (
                <Navigate
                  replace={true}
                  to={{
                    ...sandboxExplorerRouteConfig.location({}),
                    search: location.search,
                  }}
                />
              )}
            />
            <Route
              // List of app routes that do not require the user to be logged in
              path={[
                sandboxGraphRouteConfig.definition,
                atlasExplorerRouteConfig.definition,
                '/public',
              ]}
              component={AppLayout}
            />
            <LoggedInRoute path="/" component={AppLayout} />
          </Switch>
        )}
      </React.Suspense>
    </ErrorBoundary>
  );
});
