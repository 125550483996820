import { useEffect } from 'react';

import { AmplitudeHelpers } from 'src/lib/analytics/amplitude-helpers';
import { useLDFlag } from 'src/lib/launchDarkly';

export const useSyncAmplitudeForEmbedded = () => {
  const trackEmbeddedUsers = useLDFlag('betelgeuse-amplitude-in-embedded');

  useEffect(() => {
    if (trackEmbeddedUsers) {
      AmplitudeHelpers.maybePhoneHome();
    }
  }, [trackEmbeddedUsers]);
};
