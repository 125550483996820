import React from 'react';
import { useLocation, useRouteMatch } from 'react-router';

import { isEmbeddableExplorerRoute } from 'src/app/embeddableExplorer/isEmbeddableExplorerRoute';
import { useRouteParams } from 'src/hooks/useRouteParams';
import { catchAllRouteConfig } from 'src/lib/routeConfig/catchAllRoute';
import { RouteConfig } from 'src/lib/routeConfig/RouteConfig';

import {
  atlasExplorerRouteConfig,
  buildCheckDetailsRoute,
  customCheckDetailsRoute,
  downstreamCheckDetailsRoute,
  embeddableExplorerRouteConfig,
  explorerRouteConfig,
  fieldRouteConfig,
  filterBuildCheckDetailsRoute,
  lintCheckDetailsRoute,
  operationsCheckDetailsRoute,
  proposalBuildCheckDetailsRoute,
  proposalCheckDetailsRoute,
  proposalChecksListRouteConfig,
  proposalCustomCheckDetailsRoute,
  proposalDownstreamCheckDetailsRoute,
  proposalFilterBuildCheckDetailsRoute,
  proposalHomepageRouteConfig,
  proposalLintCheckDetailsRoute,
  proposalOperationsCheckDetailsRoute,
  proposalProposalCheckDetailsRoute,
  proposalReferenceRouteConfig,
  proposalRouteConfig,
  proposalSchemaRouteConfig,
  proposalVisualizationRouteConfig,
  referenceRouteConfig,
  sandboxExplorerRouteConfig,
  sandboxGraphRouteConfig,
  sandboxReferenceRouteConfig,
  sandboxSchemaRouteConfig,
  sandboxSdlRouteConfig,
  sandboxVisualizationRouteConfig,
  schemaRouteConfig,
  sdlRouteConfig,
  variantRouteConfig,
  variantRouteConfigWithChecksFilters,
  visualizationRouteConfig,
} from '../routes';

import { useGraphRef } from './useGraphRef';

type ExplorerParams = Parameters<typeof sandboxExplorerRouteConfig.location>[0];
type SDLParams = Parameters<typeof sandboxSdlRouteConfig.location>[0];
export type VisualizationParams = Parameters<
  typeof sandboxVisualizationRouteConfig.location
>[0];
type VisualizationPatch = Parameters<
  typeof sandboxVisualizationRouteConfig.locationFrom
>[0]['patch'];
export type SDLPatch = Parameters<
  typeof sandboxSdlRouteConfig.locationFrom
>[0]['patch'];
type ReferenceParams = Parameters<
  typeof sandboxReferenceRouteConfig.location
>[0];
type SchemaParams = Parameters<typeof sandboxSchemaRouteConfig.location>[0];
export type ReferencePatch = Parameters<
  typeof sandboxReferenceRouteConfig.locationFrom
>[0]['patch'];
type FieldParams = Parameters<typeof fieldRouteConfig.location>[0];

/**
 * We have multiple versions of graph routes (public/private/sandbox) for the
 * explorer and documentation pages, and possibly more later.
 *
 * The intent here is to return the same 'version' of route links as to what
 * you're currently on. If I'm viewing a sandbox explorer, I would not want to
 * link out to a private schema page route, I would want to link to the sandbox
 * schema page.
 *
 * Each of the `locationTo{routeConfig}` is meant to replace
 * `{routeConfig}.location`, 'pathTo' replacing '.path', and 'patchLink'
 * replacing '.locationFrom'.
 *
 * We also return the route configs for the current version for usage with
 * `useRouteParams`, this is more to keep some existing type weirdness around
 * the `useRouteParams` generics in one place until that gets fixed (though we
 * might decide this is more ergonomic anyways).
 *
 * The 'no-direct-route-config-access' custom lint rule enforces usage of this
 * over specific route configs within the explorer and schema page folders.
 */
export function useInternalGraphLinking(isRelativeLink = true) {
  const isSandboxRoute =
    useRouteMatch(sandboxGraphRouteConfig.definition) !== null;
  const isAtlasRoute =
    useRouteMatch(atlasExplorerRouteConfig.definition) !== null;
  const isEmbeddableRoute = isEmbeddableExplorerRoute();
  const isFieldRoute = useRouteMatch(fieldRouteConfig.definition) !== null;
  const isProposalRoute =
    useRouteMatch(proposalRouteConfig.definition) !== null;
  const isProposalHomepageRoute =
    useRouteMatch(proposalHomepageRouteConfig.definition) !== null;
  const isVisualizationRoute =
    useRouteMatch(visualizationRouteConfig.definition) !== null;

  const { graphVisibilityType } = useRouteParams(
    variantRouteConfig,
    catchAllRouteConfig,
  );
  const graphRef = useGraphRef();
  const location = useLocation();

  // The below `any` assertions come from not being able to define a route
  // config type with a subset of the params that matches each of the
  // possible configs. I believe this comes from the same generics being used
  // both as field types, and also as function paramater types, meaning that
  // even if config A had a subset the params of config B, it would not be
  // assignable. (Eg if referenceRouteConfig extends baseReferenceRouteConfig)
  // TODO if we figure out a way around this, we should update below to not
  // need to use any.

  const currentExplorerRouteConfig = isSandboxRoute
    ? sandboxExplorerRouteConfig
    : isAtlasRoute
    ? atlasExplorerRouteConfig
    : isEmbeddableRoute && isRelativeLink
    ? embeddableExplorerRouteConfig
    : explorerRouteConfig;

  const currentSdlRouteConfig = isSandboxRoute
    ? sandboxSdlRouteConfig
    : isFieldRoute
    ? fieldRouteConfig
    : sdlRouteConfig;

  const currentReferenceRouteConfig = isProposalRoute
    ? proposalReferenceRouteConfig
    : isSandboxRoute
    ? sandboxReferenceRouteConfig
    : referenceRouteConfig;

  const currentVisualizationRouteConfig = isProposalRoute
    ? proposalVisualizationRouteConfig
    : isSandboxRoute
    ? sandboxVisualizationRouteConfig
    : visualizationRouteConfig;

  const currentSchemaRouteConfig = isProposalRoute
    ? proposalSchemaRouteConfig
    : isSandboxRoute
    ? sandboxSchemaRouteConfig
    : schemaRouteConfig;

  const currentChecksRouteConfig = isProposalRoute
    ? isProposalHomepageRoute
      ? proposalHomepageRouteConfig
      : proposalChecksListRouteConfig
    : variantRouteConfigWithChecksFilters;

  const {
    currentDownstreamCheckDetailsRoute,
    currentBuildCheckDetailsRoute,
    currentOperationsCheckDetailsRoute,
    currentFilterBuildCheckDetailsRoute,
    currentLintCheckDetailsRoute,
    currentCustomCheckDetailsRoute,
    currentProposalCheckDetailsRoute,
  } = {
    currentDownstreamCheckDetailsRoute: isProposalRoute
      ? proposalDownstreamCheckDetailsRoute
      : downstreamCheckDetailsRoute,
    currentBuildCheckDetailsRoute: isProposalRoute
      ? proposalBuildCheckDetailsRoute
      : buildCheckDetailsRoute,
    currentOperationsCheckDetailsRoute: isProposalRoute
      ? proposalOperationsCheckDetailsRoute
      : operationsCheckDetailsRoute,
    currentFilterBuildCheckDetailsRoute: isProposalRoute
      ? proposalFilterBuildCheckDetailsRoute
      : filterBuildCheckDetailsRoute,
    currentLintCheckDetailsRoute: isProposalRoute
      ? proposalLintCheckDetailsRoute
      : lintCheckDetailsRoute,
    currentCustomCheckDetailsRoute: isProposalRoute
      ? proposalCustomCheckDetailsRoute
      : customCheckDetailsRoute,
    currentProposalCheckDetailsRoute: isProposalRoute
      ? proposalProposalCheckDetailsRoute
      : proposalCheckDetailsRoute,
  };

  const isExplorer =
    useRouteMatch(currentExplorerRouteConfig.definition) !== null;

  return React.useMemo(
    () => ({
      locationToExplorer: (params: ExplorerParams) => {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.location(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.location(params);
        }
        if (graphRef) {
          return explorerRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      pathToExplorer: (params: ExplorerParams) => {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.path(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.path(params);
        }
        if (graphRef) {
          return explorerRouteConfig.path({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      locationToSDL: (params: SDLParams) => {
        if (isSandboxRoute) {
          return sandboxSdlRouteConfig.location(params);
        }
        if (graphRef) {
          return sdlRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      locationToVisualization: (params: VisualizationParams) => {
        if (isSandboxRoute) {
          return sandboxVisualizationRouteConfig.location(params);
        }
        if (graphRef) {
          if (isProposalRoute) {
            return proposalVisualizationRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          } else {
            return visualizationRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          }
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      sdlFlags: {
        omitUrl: isFieldRoute,
        redirectOnLineCopy: !isFieldRoute,
      },
      locationToReference: (params: ReferenceParams) => {
        if (isSandboxRoute) {
          return sandboxReferenceRouteConfig.location(params);
        } else if (graphRef) {
          if (isProposalRoute) {
            return proposalReferenceRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          } else {
            return referenceRouteConfig.location({
              ...graphRef,
              graphVisibilityType,
              ...params,
            });
          }
        } else {
          throw new Error(
            `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
          );
        }
      },
      locationToSchema: (params: SchemaParams) => {
        if (isSandboxRoute) {
          return sandboxSchemaRouteConfig.location(params);
        }
        if (graphRef) {
          return schemaRouteConfig.location({
            ...graphRef,
            graphVisibilityType,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      patchLinkToSdl: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
      >(
        patch: SDLPatch,
        fromRouteConfig?: RouteConfig<MatchParams, SearchParams, State, Hash>,
      ) => {
        if (isSandboxRoute) {
          return sandboxSdlRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return sdlRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      patchLinkToReference: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
      >(
        patch: ReferencePatch,
        fromRouteConfig?: RouteConfig<MatchParams, SearchParams, State, Hash>,
      ) => {
        if (isSandboxRoute) {
          return sandboxReferenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else if (isProposalRoute) {
          return proposalReferenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return referenceRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      patchLinkToVisualization: <
        MatchParams extends Record<string, unknown>,
        SearchParams extends Record<string, unknown>,
        State,
        Hash,
      >(
        patch: VisualizationPatch,
        fromRouteConfig?: RouteConfig<MatchParams, SearchParams, State, Hash>,
      ) => {
        if (isSandboxRoute) {
          return sandboxVisualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else if (isProposalRoute) {
          return proposalVisualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        } else {
          return visualizationRouteConfig.locationFrom({
            location,
            patch,
            fromRouteConfig,
          });
        }
      },
      locationToFieldInsights(
        params: Pick<
          FieldParams,
          'fieldName' | 'typeName' | 'insightsTab' | 'coordinateKind'
        >,
      ) {
        if (isSandboxRoute) {
          return sandboxExplorerRouteConfig.location(params);
        }
        if (isEmbeddableRoute && isRelativeLink) {
          return embeddableExplorerRouteConfig.location(params);
        }
        if (graphRef) {
          return fieldRouteConfig.location({
            ...graphRef,
            ...params,
          });
        }
        throw new Error(
          `Matched path had no graph ref but was not a sandbox route '${location.pathname}'`,
        );
      },
      currentExplorerRouteConfig,
      currentSdlRouteConfig,
      currentReferenceRouteConfig,
      currentSchemaRouteConfig,
      currentChecksRouteConfig,
      currentVisualizationRouteConfig,
      currentBuildCheckDetailsRoute,
      currentDownstreamCheckDetailsRoute,
      currentFilterBuildCheckDetailsRoute,
      currentLintCheckDetailsRoute,
      currentOperationsCheckDetailsRoute,
      currentCustomCheckDetailsRoute,
      currentProposalCheckDetailsRoute,
      isSandboxRoute,
      isExplorer,
      isProposalRoute,
      isVisualizationRoute,
    }),
    [
      isFieldRoute,
      currentExplorerRouteConfig,
      currentSdlRouteConfig,
      currentReferenceRouteConfig,
      currentSchemaRouteConfig,
      currentChecksRouteConfig,
      currentVisualizationRouteConfig,
      currentBuildCheckDetailsRoute,
      currentDownstreamCheckDetailsRoute,
      currentFilterBuildCheckDetailsRoute,
      currentLintCheckDetailsRoute,
      currentOperationsCheckDetailsRoute,
      currentCustomCheckDetailsRoute,
      currentProposalCheckDetailsRoute,
      isSandboxRoute,
      isExplorer,
      isProposalRoute,
      isVisualizationRoute,
      isEmbeddableRoute,
      isRelativeLink,
      graphRef,
      location,
      graphVisibilityType,
    ],
  );
}
