import { HeaderEntry } from '../graph/explorerPage/hooks/useExplorerState/useHeadersManagerContext/shared';
import {
  EmbedDefaultRouteFragmentType,
  EmbeddedSandboxRouteFragmentType,
  sandboxGraphRouteConfig,
} from '../graph/routes';

type SandboxEmbedSpecificConfigOptions = Omit<
  ReturnType<EmbeddedSandboxRouteFragmentType['parseSearchParams']>,
  | 'endpointIsEditable'
  | 'hideCookieToggle'
  | 'defaultIncludeCookies'
  | 'initialRequestQueryPlan'
  | 'shouldDefaultAutoupdateSchema'
  | 'sharedHeaders'
  | 'sendOperationHeadersInIntrospection'
  | 'initialRequestConnectorsDebugging'
> & {
  // convert string 'true' or 'false' into booleans
  endpointIsEditable: boolean;
  hideCookieToggle: boolean;
  defaultIncludeCookies: boolean;
  initialRequestQueryPlan: boolean;
  initialRequestConnectorsDebugging: boolean;
  shouldDefaultAutoupdateSchema: boolean;
  // convert string shared headers into obj
  sharedHeaders?: HeaderEntry[];
  sendOperationHeadersInIntrospection: boolean;
};

type SharedEmbedConfigOptions = Omit<
  ReturnType<EmbedDefaultRouteFragmentType['parseSearchParams']>,
  'runTelemetry'
  // convert string 'true' or 'false' into booleans
> & { runTelemetry: boolean };

type SharedSandboxConfigOptions = Omit<
  ReturnType<typeof sandboxGraphRouteConfig['parseSearchParams']>,
  'endpoint'
>;

// Require all the booleans to be not undefined
export type EmbeddableSandboxConfig = Required<
  Omit<
    SharedEmbedConfigOptions &
      SandboxEmbedSpecificConfigOptions &
      SharedSandboxConfigOptions,
    'version' | 'sharedHeaders' | 'subscriptionEndpoint'
  >
> &
  Pick<
    SharedEmbedConfigOptions &
      SandboxEmbedSpecificConfigOptions &
      SharedSandboxConfigOptions,
    'version' | 'sharedHeaders' | 'subscriptionEndpoint'
  >;

export const STABLE_DEFAULT_EMBEDDABLE_SANDBOX_CONFIG: EmbeddableSandboxConfig =
  {
    endpointIsEditable: true,
    hideCookieToggle: true,
    defaultIncludeCookies: false,
    initialRequestQueryPlan: false,
    shouldDefaultAutoupdateSchema: true,
    sharedHeaders: undefined,
    version: undefined,
    runTelemetry: false,
    subscriptionEndpoint: undefined,
    sendOperationHeadersInIntrospection: true,
    initialRequestConnectorsDebugging: false,
  };
