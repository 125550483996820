import { cssVar, defineStyle, defineStyleConfig } from '@chakra-ui/react';

import { customColors } from '../../theme/customColors';
import { linkBaseStyle } from '../Link/Link.style';

const { bg, text, border, icon, brand, btn } = customColors;
export const $iconSize = cssVar('button-icon-size');
export const $iconColor = cssVar('button-icon-color', icon.primary);

export const $splitBorderColor = cssVar(
  'button-split-border-color',
  border.splitbutton,
);

const baseStyle = defineStyle({
  [$splitBorderColor.variable]: border.primary,
  gap: 2,
  fontFamily: 'body',
  fontWeight: 'semibold',
  alignItems: 'center',
  _disabled: {
    opacity: false,
    backgroundColor: bg.disabled,
    color: text.disabled,
    [$splitBorderColor.variable]: border.disabled,
    '& svg': {
      [$iconColor.variable]: icon.disabled,
    },
  },
  _focus: {
    boxShadow: 'unset',
  },
  _focusVisible: {
    outlineColor: border.focused,
    boxShadow: 'unset',
  },
  '& span': {
    display: 'inline-flex',
    alignItems: 'center',
  },
  '& svg': {
    w: $iconSize.reference,
    h: $iconSize.reference,
    color: $iconColor.reference,
  },
});

const buttonLink = defineStyle({
  ...linkBaseStyle,
  [$iconColor.variable]: 'currentColor',
  p: 0,
  backgroundColor: 'transparent',
  verticalAlign: false,
  _disabled: {
    backgroundColor: false,
  },
});

const primary = defineStyle({
  [$splitBorderColor.variable]: border.splitbutton,
  [$iconColor.variable]: icon.white,
  backgroundColor: btn.primary,
  color: text.white,
  _hover: {
    backgroundColor: btn['primary-hover'],
    color: text.white,
    _disabled: {
      backgroundColor: bg.disabled,
      color: text.disabled,
    },
  },
  _active: {
    backgroundColor: btn['primary-selected'],
    color: text.white,
  },
  _disabled: {
    [$splitBorderColor.variable]: border.disabled,
    backgroundColor: bg.disabled,
    color: text.disabled,
  },
});

const secondary = defineStyle({
  [$splitBorderColor.variable]: border.primary,
  backgroundColor: btn.secondary,
  color: text.primary,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: border.primary,
  _hover: {
    backgroundColor: btn['secondary-hover'],
    color: text.primary,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: border.primary,
    _disabled: {
      backgroundColor: bg.disabled,
      color: text.disabled,
      borderColor: 'transparent',
    },
  },
  _active: {
    backgroundColor: btn['secondary-selected'],
    color: text.primary,
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  _disabled: {
    [$splitBorderColor.variable]: border.disabled,
    backgroundColor: bg.disabled,
    color: text.disabled,
    borderColor: 'transparent',
  },
});

const hidden = defineStyle({
  [$splitBorderColor.variable]: 'transparent',
  backgroundColor: 'transparent',
  color: text.primary,
  _hover: {
    backgroundColor: btn['secondary-hover'],
    _disabled: {
      backgroundColor: bg.disabled,
      color: text.disabled,
    },
  },
  _active: {
    boxShadow: 'none',
    backgroundColor: btn['secondary-selected'],
    color: text.primary,
  },
  _disabled: {
    backgroundColor: bg.disabled,
    color: text.disabled,
    [$splitBorderColor.variable]: border.disabled,
  },
});

const destructive = defineStyle({
  [$splitBorderColor.variable]: border.primary,
  [$iconColor.variable]: icon.white,
  backgroundColor: btn.destructive,
  color: text.white,
  _hover: {
    backgroundColor: btn['destructive-hover'],
    _disabled: {
      backgroundColor: bg.disabled,
      color: text.disabled,
    },
  },
  _disabled: {
    backgroundColor: bg.disabled,
    color: text.disabled,
    [$splitBorderColor.variable]: border.disabled,
  },
});

const brandDark = defineStyle({
  [$iconColor.variable]: icon.white,
  [$splitBorderColor.variable]: border.primary,
  bg: brand.primary,
  color: text.white,
  border: '1px solid transparent',
  _hover: {
    [$iconColor.variable]: 'currentColor',
    background: 'transparent',
    border: `1px solid ${brand.primary}`,
    color: brand.primary,
    _disabled: {
      borderColor: 'transparent',
      backgroundColor: bg.disabled,
      color: text.disabled,
    },
  },
  _disabled: {
    backgroundColor: bg.disabled,
    color: text.disabled,
    [$splitBorderColor.variable]: border.disabled,
  },
});

const brandLight = defineStyle({
  [$splitBorderColor.variable]: border.primary,
  [$iconColor.variable]: icon.white,
  bg: brand.secondary,
  color: text.white,
  _loading: { color: text.white },
  border: '1px solid transparent',
  _hover: {
    [$iconColor.variable]: 'currentColor',
    background: 'transparent',
    border: `1px solid ${brand.secondary}`,
    color: brand.secondary,
    _disabled: {
      backgroundColor: bg.disabled,
      color: text.disabled,
      borderColor: 'transparent',
    },
  },
  _disabled: {
    [$splitBorderColor.variable]: border.disabled,
    backgroundColor: bg.disabled,
    color: text.disabled,
  },
});

const buttonLinkOverrides = defineStyle(({ variant }) =>
  variant === 'buttonLink'
    ? {
        padding: false,
        px: false,
        py: false,
        h: false,
        minW: false,
      }
    : {},
);

const lg = defineStyle((props) => ({
  [$iconSize.variable]: 'sizes.6',
  py: 3,
  px: 4,
  borderRadius: 'lg',
  fontSize: 'base',
  lineHeight: 'base',
  ...buttonLinkOverrides(props),
}));

const md = defineStyle((props) => ({
  [$iconSize.variable]: 'sizes.4',
  py: 2,
  px: 3,
  borderRadius: 'lg',
  fontSize: 'base',
  lineHeight: 'base',
  ...buttonLinkOverrides(props),
}));

const sm = defineStyle((props) => ({
  [$iconSize.variable]: 'sizes.4',
  py: 2,
  px: 3,
  borderRadius: 'base',
  fontSize: 'sm',
  lineHeight: 'sm',
  ...buttonLinkOverrides(props),
}));

const xs = defineStyle((props) => ({
  [$iconSize.variable]: 'sizes.3',
  p: 2,
  borderRadius: 'base',
  fontSize: 'sm',
  lineHeight: 'sm',
  ...buttonLinkOverrides(props),
}));

export const ButtonStyles = defineStyleConfig({
  baseStyle,
  variants: {
    buttonLink,
    primary,
    secondary,
    hidden,
    destructive,
    brandDark,
    brandLight,
  },
  sizes: {
    lg,
    md,
    sm,
    xs,
  },
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
